import BaseService from "./base-service";

/**
 * SenderContactService manages sender contact-related operations.
 *
 * @class SenderContactService
 * @extends {BaseService}
 */
export default class SenderContactService extends BaseService {
  path = "user-mgmt/sendercontact";

  /**
   * Retrieves a list of contacts based on pagination, search, and active status.
   *
   * @param {number} page             The page number for pagination.
   * @param {number} pageSize         The number of contacts per page.
   * @param {string} searchQueryValue The search query to filter contacts.
   * @param {boolean} isContactActive Filter by active status.
   *
   * @returns {Promise}
   */
  getContacts = async (page, pageSize, searchQueryValue, isContactActive) => {
    const pageQuery = page > 1 ? `&PageNumber=${page}` : "";
    const pageSizeQuery =
      pageSize > 0 && !isNaN(+pageSize) ? `&PageSize=${pageSize}` : "";
    const searchQuery = searchQueryValue
      ? `&SearchQuery=${searchQueryValue}`
      : "";
    const isActiveQuery =
      isContactActive !== null ? `&isActive=${isContactActive}` : "";

    const query = `?${pageSizeQuery}${pageQuery}${searchQuery}${isActiveQuery}`;

    return await this.get(`${this.path}/getsendercontacts`, query);
  };

  /**
   * Adds a new contact.
   *
   * @param {Object} body The contact information.
   *
   * @returns {Promise}
   */
  addContact = async (body) =>
    await this.post(`${this.path}/createsendercontact`, body);

  /**
   * Edits an existing contact.
   *
   * @param {Object} body      The updated contact information.
   * @param {string} contactId The ID of the contact to edit.
   *
   * @returns {Promise}
   */
  editContact = async (body, contactId) =>
    await this.put(
      `${this.path}/updatesendercontact`,
      body,
      `?senderContactId=${contactId}`
    );

  /**
   * Activates a contact by its ID.
   *
   * @param {string} contactId The ID of the contact to activate.
   *
   * @returns {Promise}
   */
  activateContact = async (contactId) =>
    await this.patch(
      `${this.path}/activesendercontact`,
      {},
      `?senderContactId=${contactId}`
    );

  /**
   * Deactivates a contact by its ID.
   *
   * @param {string} contactId The ID of the contact to deactivate.
   *
   * @returns {Promise}
   */
  deactivateContact = async (contactId) =>
    await this.patch(
      `${this.path}/deactivesendercontact`,
      {},
      `?senderContactId=${contactId}`
    );

  /**
   * Fetches receiver details by their phone number.
   *
   * @param {string} phoneNumber The phone number of the receiver.
   *
   * @returns {Promise}
   */
  getReceiverPhoneNumber = async (phoneNumber) =>
    await this.get("user-mgmt/receiver", `?phoneNumber=${phoneNumber}`);
}

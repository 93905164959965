import { TokenUtils } from "utils";
import BaseService from "./base-service";

/**
 * SenderService manages sender-related operations such as addresses, profile data, and files.
 *
 * @class SenderService
 * @extends {BaseService}
 */
export default class SenderService extends BaseService {
  path = "user-mgmt/sender";

  userId = TokenUtils.getDecoded()?.UserId;

  /**
   * Retrieves a list of addresses with pagination and search options.
   *
   * @param {number} page             The page number for pagination.
   * @param {number} pageSize         The number of addresses per page.
   * @param {string} searchQueryValue The search query to filter addresses.
   *
   * @returns {Promise}
   */
  getAddresses = async (page, pageSize, searchQueryValue) => {
    const pageQuery = page > 1 ? `&PageNumber=${page}` : "";
    const pageSizeQuery =
      pageSize > 0 && !isNaN(+pageSize) ? `&PageSize=${pageSize}` : "";
    const searchQuery = searchQueryValue
      ? `&SearchQuery=${searchQueryValue}`
      : "";

    const query = `?${pageSizeQuery}${pageQuery}${searchQuery}`;

    return await this.get(`${this.path}/receivers`, query);
  };

  /**
   * Retrieves the user's information based on the decoded token.
   *
   * @returns {Promise}
   */
  getUserInfo = async () => await this.get(this.path);

  /**
   * Updates the user's profile data.
   *
   * @param {Object} body The updated profile data.
   *
   * @returns {Promise}
   */
  updateProfileData = async (body) => await this.put(this.path, body);

  /**
   * Fetches the driver's image associated with a specific order.
   *
   * @param {string} orderId The ID of the order to fetch the image for.
   *
   * @returns {Promise}
   */
  getDriverImage = async (orderId) =>
    await this.get(`${this.path}/driverpicture`, `?orderId=${orderId}`, {
      responseType: "blob",
    });

  /**
   * Retrieves the user's files based on the specified file type.
   *
   * @param {string} fileType The type of file to retrieve.
   *
   * @returns {Promise}
   */
  getUserFiles = async (fileType) =>
    await this.get(`${this.path}/getFile`, `?fileType=${fileType}`, {
      responseType: "blob",
    });

  /**
   * Updates the user's language.
   *
   * @param {Object} body The new language.
   *
   * @returns {Promise}
   */
  updateUserLanguage = async (body) =>
    await this.patch(`${this.path}/updatelanguage`, body);
}
